import Vue from "vue";
import VueCurrencyInput from "vue-currency-input";

const pluginOptions = {
  /* see documentation https://dm4t2.github.io/vue-currency-input/config/ */
  globalOptions: {
    locale: "es-ES",
    currency: null,
    // valueAsInteger: true,
    distractionFree: true,
    precision: {
      min: 2,
      max: 4,
    },
  },
  componentName: "VueCurrency",
};
Vue.use(VueCurrencyInput, pluginOptions);

export default new Vue({});
