import Intranet from "@/layouts/intranet/Index.vue";
import serviceCrudUpdate from "@/views/services/ServiceUpdate.vue";

export default {
  path: "/services",
  component: Intranet,
  redirect: { name: "servicesCrud" },
  meta: {
    title: "Serveis",
  },
  children: [
    {
      path: "list",
      name: "servicesCrud",
      component: () => import("@/views/crud/ServicioCrud.vue"),
      meta: {
        auth: true,
        serviceName: "servicios",
        title: "Llista de serveis",
      },
      children: [
        {
          path: ":id",
          name: "servicesCrudUpdate",
          component: serviceCrudUpdate,
          meta: {
            auth: false,
            serviceName: "servicios",
            title: "Servei",
          },
        },
      ],
    },

    {
      path: "list-quote",
      name: "servicesQuoteCrud",
      component: () => import("@/views/crud/ServicioPresupuestosCrud.vue"),
      meta: {
        auth: true,
        serviceName: "servicio-presupuestos",
        title: "Llista de serveis - Pressupost",
      },
      children: [
        {
          path: ":id",
          name: "servicesQuoteCrudUpdate",
          component: serviceCrudUpdate,
          meta: {
            auth: false,
            serviceName: "servicio-presupuestos",
            title: "Servei",
          },
        },
      ],
    },

    {
      path: "list-command",
      name: "servicesCommandCrud",
      component: () => import("@/views/crud/ServicioComandasCrud.vue"),
      meta: {
        auth: true,
        serviceName: "servicio-comandas",
        title: "Llista de serveis - Comanda",
      },
      children: [
        {
          path: ":id",
          name: "servicesCommandCrudUpdate",
          component: serviceCrudUpdate,
          meta: {
            auth: false,
            serviceName: "servicio-comandas",
            title: "Servei",
          },
        },
      ],
    },

    {
      path: "list-mando",
      name: "servicesMandoCrud",
      component: () => import("@/views/crud/ServicioMandosCrud.vue"),
      meta: {
        auth: true,
        serviceName: "servicio-mandos",
        title: "Llista de serveis - Mandos",
      },
      children: [
        {
          path: ":id",
          name: "servicesMandoCrudUpdate",
          component: serviceCrudUpdate,
          meta: {
            auth: false,
            serviceName: "servicio-mandos",
            title: "Servei",
          },
        },
      ],
    },

    {
      path: "list-breakdown",
      name: "servicesBreakdownCrud",
      component: () => import("@/views/crud/ServicioAveriasCrud.vue"),
      meta: {
        auth: true,
        serviceName: "servicio-averias",
        title: "Llista de serveis - Averies",
      },
      children: [
        {
          path: ":id",
          name: "servicesBreakdownCrudUpdate",
          component: serviceCrudUpdate,
          meta: {
            auth: false,
            serviceName: "servicio-averias",
            title: "Servei",
          },
        },
      ],
    },

    {
      path: "map",
      name: "servicesScheduleMap",
      component: () => import("@/views/services/scheduleMap"),
      meta: {
        auth: true,
        serviceName: "servicesMap",
        title: "Planificació de serveis",
      },
    },
  ],
};
