import apiClient from "@/services/ApiClient";

export function crudActions(serviceName) {
  return {
    name() {
      return serviceName;
    },
    getListHeaders() {
      return apiClient.get(`/${serviceName}/headers`);
    },
    getListItems(searchTerm) {
      const query = searchTerm ? `?search=${searchTerm}` : "";
      return apiClient.get(`/${serviceName}/items${query}`);
    },
    getListHeadersByClient(params) {
      const queryParams = Object.keys(params)
        .map(key => `${key}=${params[key]}`)
        .join("&");
      return apiClient.get(`/${serviceName}/headers?${queryParams}`); // TODO treure interrogant quan no hi ha queryParams
    },
    getListItemsByClient(params) {
      const queryParams = Object.keys(params)
        .map(key => `${key}=${params[key]}`)
        .join("&");
      return apiClient.get(`/${serviceName}/items?${queryParams}`);
    },
    getListItem(id) {
      return apiClient.get(`/${serviceName}/items/${id}`);
    },
    getItem(id) {
      return apiClient.get(`/${serviceName}/${id}`);
    },
    newItem(params) {
      if (params) {
        const queryParams = Object.keys(params)
          .map(key => `${key}=${params[key]}`)
          .join("&");
        return apiClient.get(`/${serviceName}/new?${queryParams}`); // TODO treure interrogant quan no hi ha queryParams
      } else {
        return apiClient.get(`/${serviceName}/new`);
      }
    },
    createItem(data) {
      return apiClient.post(`${serviceName}`, data);
    },
    updateItem(id, event) {
      return apiClient.put(`/${serviceName}/${id}`, event);
    },
    newCloneItem(id) {
      return apiClient.get(`/${serviceName}/clone/${id}`);
    },
    createCloneItem(id, data) {
      return apiClient.post(`/${serviceName}/clone/${id}`, data);
    },
    deleteItems(ids) {
      return apiClient.post(`/${serviceName}/delete`, { id: ids });
    },
    saveColumns(data) {
      return apiClient.post("/crud-configurations/save-columns", {
        name: serviceName,
        data: data,
      });
    },
  };
}
