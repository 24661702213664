import billingRoutes from "@/modules/automatismes-enric-admin/routes/billingRoutes";
import clientsRoutes from "@/modules/automatismes-enric-admin/routes/clientsRoutes";
import contactsRoutes from "@/modules/automatismes-enric-admin/routes/contactsRoutes";
import doorsRoutes from "@/modules/automatismes-enric-admin/routes/doorsRoutes";
import mailingTemplatesRoutes from "@/modules/automatismes-enric-admin/routes/mailingTemplatesRoutes";
import productsRoutes from "@/modules/automatismes-enric-admin/routes/productsRoutes";
import propertiersAdministrators from "@/modules/automatismes-enric-admin/routes/propertiesAdministratorsRoutes";
import servicesRoutes from "@/modules/automatismes-enric-admin/routes/servicesRoutes";
import settingsRoutes from "@/modules/automatismes-enric-admin/routes/settingsRoutes";
import workOrdersRoutes from "@/modules/automatismes-enric-admin/routes/workOrdersRoutes";

export default [
  billingRoutes,
  clientsRoutes,
  contactsRoutes,
  doorsRoutes,
  mailingTemplatesRoutes,
  productsRoutes,
  propertiersAdministrators,
  servicesRoutes,
  settingsRoutes,
  workOrdersRoutes,
];
