import Intranet from "@/layouts/intranet/Index.vue";
import crudUpdate from "@/components/crud_ag/actions/Update";

export default {
  path: "/billing",
  component: Intranet,
  redirect: { name: "deliverynoteCrud" },
  meta: {
    title: "Facturació",
  },
  children: [
    {
      path: "invoices",
      name: "invoicesCrud",
      component: () => import("@/views/crud/FacturasCrud.vue"),
      meta: {
        auth: true,
        serviceName: "facturas",
        title: "Factures",
      },
      children: [
        {
          path: ":id",
          name: "invoicesCrudUpdate",
          component: () => import("@/views/billing/invoice/Edit.vue"),
          meta: {
            auth: true,
            serviceName: "facturas",
            title: "Factura",
          },
        },
      ],
    },
    {
      path: "delivery-note",
      name: "deliverynoteCrud",
      component: () => import("@/views/crud/AlbaranesCrud.vue"),
      meta: {
        auth: true,
        serviceName: "albaranes",
        title: "Albarans",
      },
      children: [
        {
          path: ":id",
          name: "deliverynoteCrudUpdate",
          component: () => import("@/views/billing/delivery-note/Edit.vue"),
          meta: {
            auth: true,
            serviceName: "albaranes",
            title: "Albarà",
          },
        },
      ],
    },
    {
      path: "commands",
      name: "commandCrud",
      component: () => import("@/views/crud/ComandaCrud.vue"),
      meta: {
        auth: true,
        serviceName: "partes-comandas",
        title: "Comandes",
      },
      children: [
        {
          path: ":id",
          name: "commandCrudUpdate",
          component: () => import("@/views/billing/work-order/Edit.vue"),
          meta: {
            auth: true,
            serviceName: "partes-comandas",
            title: "Comanda",
          },
        },
      ],
    },
    {
      path: "quotes",
      name: "quotesCrud",
      component: () => import("@/views/crud/PresupuestoCrud"),
      meta: {
        auth: true,
        serviceName: "presupuestos",
        title: "Pressupostos",
      },
      children: [
        {
          path: ":id",
          name: "quotesCrudUpdate",
          component: () => import("@/views/billing/work-order/Edit"),
          meta: {
            auth: true,
            serviceName: "presupuestos",
            title: "Pressupost",
          },
        },
      ],
    },
    {
      path: "commissions",
      name: "commissionsCrud",
      component: () => import("@/views/crud/CommissionsCrud"),
      meta: {
        auth: true,
        serviceName: "comisiones",
        title: "Comissions",
      },
      children: [
        {
          path: ":id",
          name: "commissionsCrudUpdate",
          component: crudUpdate,
          meta: {
            auth: true,
            serviceName: "comisiones",
            title: "Comissió",
          },
        },
      ],
    },
  ],
};
