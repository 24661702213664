import axios from "axios";
import store from "@/store";

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_PATH || "/api",
  withCredentials: false,
  header: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

apiClient.interceptors.response.use(
  response => response,
  error => {
    const {
      response: { status },
    } = error;
    if (status === 401) {
      if (this.$getCookie("auth_token")) {
        store.dispatch("logout");
      }
    }
    throw error;
  }
);

/*
// Cancel Token Request Interceptor
apiClient.interceptors.request.use(
  function(config) {
    //  Generate cancel token source
    let source = axios.CancelToken.source()
    // Set cancel token on this request
    config.cancelToken = source.token
    // Add to vuex to make cancellation available from anywhere
    store.commit("ADD_CANCEL_TOKEN", source)

    return config
  },
  function(error) {
    return Promise.reject(error)
  }
)
 */

export default apiClient;
