<template>
  <crud-update>
    <template v-slot:title-icons>
      <span v-if="customerInfo">| {{ customerInfo.nombre }}</span>
      <router-link
        v-if="customerInfo"
        :to="{
          name: 'clientsCrudUpdate',
          params: { id: customerInfo.id },
        }"
      >
        <v-icon size="18" color="primary" class="ml-2">exit_to_app</v-icon>
      </router-link>
      <v-btn icon color="primary" class="ml-2" @click="toggleCustomerChangeDialog">
        <v-icon size="18"> mdi-account-convert </v-icon>
      </v-btn>
      <customer-change-dialog
        :customer-change-dialog="showCustomerChangeDialog"
        @close-dialog="toggleCustomerChangeDialog"
        @update-customer="updateCustomer"
      />
    </template>
  </crud-update>
</template>

<script>
import ServicioService from "@/modules/automatismes-enric-admin/services/servicesService";

export default {
  components: {
    CrudUpdate: () => import("@/components/crud_ag/actions/Update"),
    CustomerChangeDialog: () => import("@/components/billing/quote/CustomerChangeDialog"),
  },
  data() {
    return {
      showCustomerChangeDialog: false,
      customerInfo: null,
    };
  },
  beforeMount() {
    ServicioService.getCustomer(this.$route.params.id)
      .then(res => {
        this.customerInfo = res.data;
      })
      .catch(() => this.$notifyError());
  },
  methods: {
    toggleCustomerChangeDialog() {
      this.showCustomerChangeDialog = !this.showCustomerChangeDialog;
    },
    handleCTA(functionName, event) {
      this[functionName](event);
    },
    updateCustomer(item) {
      ServicioService.changeCustomer(this.$route.params.id, item.id)
        .then(() => {
          this.customerInfo.id = item.id;
          this.customerInfo.nombre = item.nombre;
          this.$root.$emit("refreshCrudItems");
          this.$notifySuccess();
        })
        .catch(() => this.$notifyError());
    },
  },
};
</script>

<style scoped></style>
