<template>
  <view-base @cancel="cancel">
    <div class="fill-height d-flex flex-column align-stretch prd-maxwidth-100">
      <div class="flex-shrink-1 flex-grow-0 prd-maxwidth-100">
        <theHeading :heading-override="itemTitle">
          <template v-slot:title-tools>
            <slot name="title-icons"></slot>
          </template>
          <template v-slot:tools>
            <v-btn color="error" small @click="cancel" text> Tancar </v-btn>
            <v-btn
              v-if="currentComponent === 'form-vjsf'"
              type="submit"
              :form="formId"
              color="success"
              class="px-6 mx-4"
              :loading="submitLoading"
              small
              text
            >
              Guardar
            </v-btn>
            <v-btn
              v-if="currentComponent === 'form-vjsf'"
              @click.stop="submitForm(true)"
              :form="formId"
              color="success"
              class="px-6"
              :loading="submitLoading"
              small
              depressed
            >
              Guardar i tancar
            </v-btn>
          </template>
        </theHeading>
      </div>

      <v-col
        v-if="isLoading"
        class="d-flex align-center justify-center fill-height prd-maxwidth-100"
      >
        <v-progress-circular class="my-12" :size="50" :color="loadingColor" indeterminate />
      </v-col>

      <div class="flex-shrink-0 flex-grow-1 d-flex flex-column prd-maxwidth-100">
        <v-tabs
          v-model="currentTab"
          v-if="updateActions.length > 1"
          class="my-4 flex-grow-0 flex-shrink-1 prd-maxwidth-100"
          show-arrows
          next-icon="keyboard_arrow_right"
          prev-icon="keyboard_arrow_left"
          height="40"
          grow
        >
          <v-tab v-for="tab in updateActions" :key="tab.title" @click="handleTabClick" class="px-6">
            {{ tab.title }}
          </v-tab>
        </v-tabs>

        <div class="flex-grow-1 flex-shrink-0 d-flex prd-maxwidth-100">
          <!--<keep-alive>-->
          <component
            :key="currentTab"
            :is="currentComponent"
            v-bind="customProps"
            @submitForm="submitForm($event)"
          />
          <!--</keep-alive>-->
        </div>
      </div>
    </div>
  </view-base>
</template>

<script>
import serviceFactory from "@/services/ServiceFactory";
import { updateMixin } from "@/mixins/crud";

export default {
  mixins: [updateMixin],
  components: {
    FormVjsf: () => import("@/components/FormVjsf/Vjsf"),
    CrudFactory: () => import("@/views/crud/CrudFactory.vue"),
    ViewBase: () => import("@/layouts/intranet/ViewBase"),
    theHeading: () => import("@/layouts/intranet/components/viewHeading.vue"),
  },
  props: {
    isChildrenCrud: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    formData: {
      id: null,
      model: {},
      schema: {},
      options: {},
    },
    updateActions: [],
    availableComponents: {
      form: "form-vjsf",
      crud: "crud-factory",
    },
    backdoor: 0,
    itemId: null,
    submitLoading: null,
  }),
  methods: {
    submitForm(close = false) {
      this.submitLoading = true;
      this.$store.state.currentService
        .updateItem(this.formData.id, this.formData.model)
        .then(response => {
          const { errorMessage } = response.data;
          if (errorMessage) {
            this.$notifyError(undefined, errorMessage);
          } else {
            this.$root.$emit("refreshCrudItems");
            if (close === true) this.closeDialog();
          }
        })
        .catch(() => {
          this.$notifyError();
        })
        .finally(() => {
          this.submitLoading = false;
        });
    },

    handleTabClick() {
      this.backdoor++;
    },
  },
  computed: {
    currentTab: {
      get() {
        const queryTab = parseInt(this.$route.query.tab) - 1 || 0;
        if (this.updateActions) {
          return this.updateActions[queryTab] ? queryTab : 0;
        } else {
          return 0;
        }
      },
      set(newValue) {
        this.$router.push({
          query: { tab: parseInt(newValue) + 1 },
        });
        return newValue;
      },
    },
    formId() {
      return this.$store.state.currentService.name() + "_" + this.itemId;
    },
    currentComponent() {
      //Return component Type name
      if (this.updateActions.length > 0) {
        const componentType = this.currentTab !== null && this.updateActions[this.currentTab].type;
        return componentType && this.availableComponents[componentType];
      } else {
        return null;
      }
    },
    customProps() {
      let prop = null;

      const currentAction = this.updateActions[this.currentTab];

      if (currentAction && currentAction.type === "form") {
        prop = {
          key: this.contentKey,
          formId: this.formId,
          formData: this.formData,
          style: "width: 100%",
        };
      } else if (currentAction && currentAction.type === "crud") {
        const { serviceName } = currentAction;
        const { params } = currentAction;

        prop = {
          service: serviceFactory.get(serviceName),
          isChildrenCrud: true,
          crudParams: params,
        };

        const storeCrudMetaData = this.$store.state.crudMetaData;

        if (
          storeCrudMetaData.childEditCustomFunction &&
          storeCrudMetaData.childEditCustomFunction[currentAction.title]
        ) {
          // if custom Edit is defined in View and matches with tab Name
          prop.customEdit = storeCrudMetaData.childEditCustomFunction[currentAction.title];
        }
      }

      return prop;
    },
  },
  mounted() {
    this.isLoading = !this.isLoading;
    this.$store.state.currentService
      .getItem(this.itemId)
      .then(response => {
        this.updateActions = response.data.tabs;
        this.isLoading = !this.isLoading;
      })
      .then(() => {
        const formAction =
          Array.isArray(this.updateActions) &&
          this.updateActions.find(action => action.type === "form");

        this.formData = formAction.form_data;

        if (this.formData && this.formData.model[this.dialogModelTitle]) {
          this.itemTitle += " | " + this.formData.model[this.dialogModelTitle];
        }
      });
  },
};
</script>

<style lang="scss" scoped></style>
