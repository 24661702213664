import Vue from "vue";

import VuetifyConfirm from "vuetify-confirm";
import vuetify from "./Vuetify";

// Confirm Dialog
Vue.use(VuetifyConfirm, {
  vuetify,
  buttonTrueText: "Sí",
  buttonFalseText: "No",
  color: "red",
  icon: "warning",
  title: "Alerta",
  width: 350,
  property: "$confirm",
});

export default new Vue({});
