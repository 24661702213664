import store from "@/store/index";
import Vue from "vue";
import VueRouter from "vue-router";
import Cookies from "js-cookie";

import Intranet from "@/layouts/intranet/Index.vue";

import adminRoutes from "@/modules/automatismes-enric-admin/routes";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    component: () => import("@/layouts/intranet_empty/Index.vue"),
    children: [
      {
        path: "",
        name: "login",
        component: () => import("@/views/UserLogin.vue"),
        meta: {
          auth: false,
          title: "Accés d'usuari",
        },
      },
    ],
  },

  {
    path: "/",
    component: Intranet,
    children: [
      {
        path: "",
        component: () => import("@/views/Dashboard.vue"),
        name: "dashboard",
        meta: {
          auth: true,
          title: "Panell de control",
          hideBreadcrumb: true,
        },
      },
    ],
  },

  ...adminRoutes,

  {
    path: "*",
    component: () => import("@/views/errors/404.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  let authRequired = to.matched.some(record => record.meta.auth);
  const loggedIn = Cookies.get("auth_token");
  if (authRequired === true && !loggedIn) {
    store.state.login_redirect = to.fullPath;
    next("/login");
  } else if (authRequired === false && loggedIn) {
    next("/");
  } else {
    next();
  }
});

export default router;
