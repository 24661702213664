import AuthService from "@/services/AuthService";

import adminServices from "@/modules/automatismes-enric-admin/services";

const services = {};
for (const service of [AuthService, ...adminServices]) {
  const name = service.name();
  services[name] = service;
}

export default {
  get: name => services[name],
};
