export const updateMixin = {
  data: () => ({
    isLoading: null,
    loadingColor: null,
    itemTitle: null,
  }),
  methods: {
    cancel() {
      this.resetContent();
      this.closeDialog();
    },
    closeDialog() {
      //redirect to CRUD path
      this.resetContent();
      this.dialog = false;
      const parentIndex = this.$route.matched.length - 2;
      const parentName = this.$route.matched[parentIndex].name;
      this.$router.push({ name: parentName, query: this.$route.query });
    },
    resetContent() {
      this.contentKey += 1;
    },
  },
  beforeMount() {
    this.loadingColor = ["success", "error", "warning", "info"][Math.floor(Math.random() * 4)];
    this.itemId = this.$route.params["id"];
    this.dialogModelTitle = this.$store.state.crudMetaData.modelKeyTitle;

    this.itemTitle = "#" + this.itemId;
  },
};
