import { crudActions } from "@/services/CrudActions";
import apiClient from "@/services/ApiClient";

export default {
  ...crudActions("facturas"),
  getItem(id) {
    return apiClient.get(`/facturas/${id}`);
  },
  updateItem(id, payload) {
    return apiClient.put(`/facturas/${id}`, payload);
  },
  getPdf(ids) {
    return apiClient.get(`/facturas/${ids}/pdf`);
  },
  sendEmail(id) {
    return apiClient.post(`/facturas/${id}/send-email`);
  },
  sync(id) {
    return apiClient.post(`/facturas/${id}/sync`);
  },
  changeCustomer(id, customer_id) {
    let params = { cliente_id: customer_id };
    return apiClient.post(`/facturas/${id}/cambio-cliente`, params);
  },
};
