import UserService from "@/modules/automatismes-enric-admin/services/usersService";
import TipoParteService from "@/modules/automatismes-enric-admin/services/workOrderTypesService";
import TipoServicioService from "@/modules/automatismes-enric-admin/services/serviceTypesService";
import ProductCategoryService from "@/modules/automatismes-enric-admin/services/productCategoriesService";
import TipoMantenimientoService from "@/modules/automatismes-enric-admin/services/maintenanceTypesService";
import ArticuloService from "@/modules/automatismes-enric-admin/services/productService";
import ArticuloFamiliaService from "@/modules/automatismes-enric-admin/services/productFamiliesService";
import ClienteService from "@/modules/automatismes-enric-admin/services/clienteService";
import ContactoService from "@/modules/automatismes-enric-admin/services/clientContactsService";
import commissionsServices from "@/modules/automatismes-enric-admin/services/commissionsServices";
import PuertaService from "@/modules/automatismes-enric-admin/services/doorsService";
import ServicioService from "@/modules/automatismes-enric-admin/services/servicesService";
import ServicioPresupuestosService from "@/modules/automatismes-enric-admin/services/serviceQuotesService";
import ServicioComandasService from "@/modules/automatismes-enric-admin/services/serviceOrdersService";
import ServicioMandosService from "@/modules/automatismes-enric-admin/services/serviceMandosService";
import ServicioAveriasService from "@/modules/automatismes-enric-admin/services/serviceBreakdownsService";
import ParteService from "@/modules/automatismes-enric-admin/services/workOrdersService";
import TipoArticuloService from "@/modules/automatismes-enric-admin/services/productTypesService";
import AdministradoresFincasService from "@/modules/automatismes-enric-admin/services/propertiesAdministratorsService";
import AlbaranesService from "@/modules/automatismes-enric-admin/services/deliveryNotesService.js";
import PuertaComponenteService from "@/modules/automatismes-enric-admin/services/doorComponentsService";
import MapService from "@/modules/automatismes-enric-admin/services/mapService";
import PaymentMethodsService from "@/modules/automatismes-enric-admin/services/paymentMethodsService";
import PresupuestoService from "@/modules/automatismes-enric-admin/services/quotesService";
import MailingTemplateService from "@/modules/automatismes-enric-admin/services/mailingTemplatesService";
import FacturaService from "@/modules/automatismes-enric-admin/services/invoicesService";
import CommandsService from "@/modules/automatismes-enric-admin/services/commandsService";

export default [
  ArticuloFamiliaService,
  ArticuloService,
  ClienteService,
  ContactoService,
  commissionsServices,
  MapService,
  ParteService,
  PuertaComponenteService,
  PuertaService,
  ServicioService,
  ServicioPresupuestosService,
  ServicioComandasService,
  ServicioMandosService,
  ServicioAveriasService,
  TipoArticuloService,
  TipoMantenimientoService,
  TipoParteService,
  ProductCategoryService,
  TipoMantenimientoService,
  TipoArticuloService,
  AdministradoresFincasService,
  AlbaranesService,
  TipoServicioService,
  UserService,
  PaymentMethodsService,
  PresupuestoService,
  MailingTemplateService,
  FacturaService,
  CommandsService,
];
